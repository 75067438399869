.team {
  margin-left: 397px;
  padding-top: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.team__box {
  width: 601px;
  margin-left: 78px;
}

.team__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-second);
  margin: 0;
  margin-top: 16px;
}

.btn.team__btn {
  margin: 0;
  margin-top: 48px;
}

.team__image {
  width: 501px;
  height: 470px;
  border-radius: 24px;
  object-fit: cover;
}

@media screen and (max-width: 1919px) {
  .team {
    margin-left: calc(153 / 1440 * 100%);
  }
}

@media screen and (max-width: 1439px) {
  .team {
    padding-top: 120px;
    width: 876px;
    margin: 0 auto;
  }

  .team__box {
    width: 422px;
    margin-left: 74px;
  }

  .team__image {
    width: 380px;
    height: 495px;
  }
}

@media screen and (max-width: 959px) {
  .team {
    width: calc(606 / 670 * 100%);
    min-width: 606px;
    margin: 0 auto;
    padding-top: 72px;
    flex-direction: column;
  }

  .team__image {
    width: 606px;
    height: 320px;
    margin: 0 auto;
  }

  .team__box {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
  }

  .team__text {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 669px) {
  .team {
    width: calc(308 / 360 * 100%);
    min-width: 308px;
    padding-top: 60px;
  }

  .team__image {
    width: 308px;
    height: 200px;
  }

  .btn.team__btn {
    margin-top: 32px;
  }
}
