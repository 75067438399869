@import url(../fonts/Inter/inter.css);
@import url(../fonts//FreeSans/freesans.css);

:root {
  --color-main: #333333;
  --color-gradient: linear-gradient(90deg, #305dd3 -0.01%, #73a3fa 100%);
  --color-second: #5c5c5c;
  --color-third: #ffffff;
  --color-four: #73a3fa;
  --color-gradient-light: linear-gradient(90deg, rgba(48, 93, 211, 0.2) -0.01%, rgba(115, 163, 250, 0.2) 100%);
}

.noscroll {
  overflow: hidden;
}

.page {
  min-height: 100vh;
  max-width: 100%;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  background: var(--color-third);
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.page.am {
  font-family: "FreeSans", Arial, sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: calc(397/1920*100%);
}

.decor {
  position: relative;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: 0.7s;
}

.link:hover {
  opacity: 0.5;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  width: 200px;
  height: 48px;
  margin: 0;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  background: var(--color-third);
  transition: 0.7s;
}

.btn:hover {
  opacity: 0.8;
}

.btn.gradient {
  background: var(--color-gradient);
  color: var(--color-third);
  margin-left: 15px;
}

.btn.border {
  position: relative;
  border-radius: 8px;
  border: solid 3px transparent;
  background-clip: padding-box;
  color: #fff;
  background: var(--color-third);
  border: 1px solid transparent;
  background-image: linear-gradient(white, white), var(--color-gradient);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.text__gradient {
  background: var(--color-gradient) transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.disabled {
  cursor: default;
  opacity: 0.3;
}

@media screen and (max-width: 1919px) {
  .content {
    margin-left: calc(157 / 1440 * 100%);
  }
}

@media screen and (max-width: 1439px) {
  .content {
    margin-left: calc(40 / 960 * 100%);
  }
}

@media screen and (max-width: 959px) {
  .content {
    margin-left: calc(32 / 670 * 100%);
  }
}

@media screen and (max-width: 669px) {
  .content {
    width: calc(320 / 360 * 100%);
    margin: 0 auto;
  }

  .btn {
    font-size: 12px;
    line-height: 15px;
    width: 160px;
    height: 44px;
  }
}
