.contacts {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 120px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.contacts__map {
  position: absolute;
  z-index: 1;
  height: 860px;
  top: 0;
  left: -10px;
}

.contacts__box {
  z-index: 3;
  margin-top: 200px;
  margin-right: 266px;
}

.contacts__title {
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  color: var(--color-third);
  margin: 0;
}

.contacts__section {
  position: relative;
  margin: 0;
  padding: 0;
  width: 405px;
  margin-top: 54px;
}

.contacts__section:nth-of-type(2) {
  margin-top: 34px;
}

.contacts__container {
  position: relative;
  width: 430px;
  color: #000000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contacts__input {
  margin: 0;
  width: 405px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border: none;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  color: var(--color-third);
  padding: 17px 0px;
}

.contacts__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 60px;
  color: var(--color-third);
  margin-bottom: 365px;
  width: 457px;
}

.contacts__item {
  display: flex;
  margin-top: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.contacts__image {
  margin-right: 18px;
  width: 20px;
  height: 20px;
}

.contacts__image.p-t {
  padding-top: 3px;
}

.contacts__tip {
  opacity: 0.5;
  margin-left: 10px;
}

.btn.contact {
  margin-top: 48px;
}

.contacts__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-third);
  opacity: 0.6; /* Firefox */
}

.contacts__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-third);
}

.contacts__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-third);
}


@media screen and (max-width: 1690px) {
  .contacts__map {
    left: -5px;
  }

  .contacts__box{
    margin-right: 20px;
  }
}

@media screen and (max-width: 1445px) {
  .contacts__map {
    left: -240px;
  }
}

@media screen and (max-width: 1439px) {
  .contacts__box {
    width: 426px;
    margin-right: calc(40 / 960 * 100%);
    margin-top: 120px;
  }

  .contacts__container {
    width: 100%;
  }

  .contacts__section {
    width: 100%;
    margin-top: 40px;
  }

  .contacts__input {
    width: 100%;
  }

  .contacts__map {
    left: -350px;
  }

  .contacts__list {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 959px) {
  .contacts {
    margin-top: 80px;
  }

  .contacts__item.mobile {
    color: var(--color-main);
  }

  .contacts__box {
    margin: 0 auto;
    margin-top: 100px;
    width: 606px;
  }

  .contacts__map {
    display: none;
  }

  .contacts__elipses {
    top: -10px;
  }
}

@media screen and (max-width: 669px) {
  .contacts {
    margin-top: 60px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contacts__box {
    margin-top: 60px;
    width: calc(320 / 360 * 100%);
    min-width: 320px;
  }

  .contacts__input {
    width: calc(320 / 360 * 100%);
    min-width: 320px;
  }

  .contacts__container {
    width: 100%;
  }

  .contacts__title {
    font-size: 32px;
    line-height: 39px;
  }

  .contacts__image {
    margin-right: 22px;
  }

  .contacts__section {
    margin-top: 24px;
    width: calc(320 / 360 * 100%);
    min-width: 320px;
  }

  .contacts__section:nth-of-type(2) {
    margin-top: 24px;
  }

  .contacts__list {
    padding-bottom: 60px;
    width: 280px;
  }

  .contacts__item {
    width: 100%;
    white-space: nowrap;
  }

  .contacts__item:first-of-type {
    white-space: normal;
  }

  .contacts__tip.am {
    white-space: normal;
  }
}
