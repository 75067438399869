@font-face {
    font-family: 'FreeSans';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("FreeSans.woff") format("woff");
}

@font-face {
    font-family: 'FreeSans';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("FreeSansBold.woff") format("woff");
}