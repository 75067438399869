.ellipse1-4 {
  z-index: 1;
  position: absolute;
  bottom: -100px;
  left: 0;
  overflow: hidden;
}

.ellipse2-3 {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.ellipse-contacts {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.ellipse-1 {
  background: url("../../images/Ellipse1.png");
  width: 515px;
  height: 1178px;
}

.ellipse-2 {
  background: url("../../images/Ellipse2.png");
  width: 732px;
  height: 700px;
}

.ellipse-3 {
  background: url("../../images/Ellipse3.png");
  width: 424px;
  height: 607px;
}

.ellipse-4 {
  background: url("../../images/Ellipse4.png");
  width: 411px;
  height: 969px;
}

@media screen and (max-width: 1919px) {
  .ellipse-2 {
    background: url("../../images/Ellipse2_m.png") no-repeat auto;
    width: 495px;
    height: 696px;
  }

  .ellipse-3 {
    background: url("../../images/Ellipse3_m.png") no-repeat auto;
    width: 184px;
    height: 570px;
  }

  .ellipse1-4 {
    bottom: -105px;
    left: -250px;
  }
}

@media screen and (max-width: 1439px) {
  .ellipse-2 {
    width: 395px;
    height: 696px;
  }

  .ellipse1-4 {
    bottom: -50px;
    left: -340px;
  }

  .ellipse2-3 {
    top: -80px;
    right: 0px;
  }
}

@media screen and (max-width: 959px) {
  .ellipse-1 {
    background: url("../../images/Ellipse1_s.png");
    width: 234px;
    height: 783px;
  }

  .ellipse-2 {
    background: url("../../images/Ellipse2_s.png");
    width: 254px;
    height: 236px;
  }

  .ellipse-3 {
    background: url("../../images/Ellipse3_s.png");
    width: 113px;
    height: 249px;
  }

  .ellipse-4 {
    background: url("../../images/Ellipse4_s.png");
    width: 165px;
    height: 645px;
  }

  .ellipse1-4 {
    bottom: 520px;
    left: 0px;
  }

  .ellipse2-3 {
    top: -70px;
  }
}

@media screen and (max-width: 669px) {
  .ellipse1-4 {
    bottom: 820px;
    left: -90px;
  }
}
