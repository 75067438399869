.specifications{
    padding: 120px 0;
    margin-left: 254px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 2;
}

.specifications__list{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 32px;
    padding: 0;
    margin: 0;
    margin-top: 46px;
    max-width: 952px;
}

.specifications__item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    width: 296px;
    padding: 18px;
    background: var(--color-third);
    border-radius: 24px;
    border: 2px solid transparent;
    background-image: linear-gradient(white, white), var(--color-gradient-light);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    transition: 0.5s;
}

.specifications__textbox{
    display: flex;
    align-items: center;
}

.specifications__text{
    max-width: 170px;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-main);
    text-overflow: ellipsis;
    overflow: hidden;
}

.specifications__img{
    width: 24px;
    height: 24px;
    margin-right: 14px;
}

.specifications__img.download{
    margin-right: 0;
}

.specifications__item:hover{
    background-image: linear-gradient(white, white), var(--color-gradient);
}

@media screen and (max-width: 1919px) {
    .specifications{
        margin-left: calc(254/1440*100%);
    }
}


@media screen and (max-width: 1439px) {
    .specifications{
        margin-left: calc(40 / 960 * 100%);
        padding-top: 195px;
    }
    .specifications__list{
        gap: 24px;
    }
    .specifications__item{
        width: 277px;
    }

    .specifications__text{
        max-width: 150px;
    }

  }

@media screen and (max-width: 959px) {
    .specifications{
        margin-left: calc(32 / 670 * 100%);
        padding-top: 110px;
    }

    .specifications__list{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .specifications__item{
        width: 291px;
    }

    .specifications__text{
        max-width: 165px;
    }
}

@media screen and (max-width:669px) {
    .specifications{
        padding: 80px 0;
        width: calc(320 / 360 * 100%);
        margin: 0 auto;
    }

    .specifications__list{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
    }

    .specifications__item{
        width: 320px;
    }

    .specifications__text{
        max-width: 190px;
    }

}