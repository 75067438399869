.nav__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav__link {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-second);
  margin: 0;
  margin-left: 32px;
}

.nav__link:first-of-type {
  margin-left: 0;
}

@media screen and (max-width: 1439px) {
  .nav__link.am {
    white-space: nowrap;
    margin-left: 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 959px) {
  .nav__links {
    display: none;
  }

  .nav__links.mobile {
    display: flex;
    flex-direction: column;
    margin-top: 220px;
  }

  .nav__link.mobile {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    color: var(--color-main);
    margin-top: 52px;
    margin-left: 0;
  }

  .nav__link.mobile:first-of-type {
    margin-top: 0;
  }
}

@media screen and (max-width: 669px) {
  .nav__link.mobile {
    font-size: 18px;
    line-height: 25px;
    margin-top: 32px;
  }

  .nav__links.mobile {
    margin-top: 60px;
  }
}
