.footer {
  background: var(--color-main);
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
  color: var(--color-third);
  margin: 0;
}

.footer__rules {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
  color: var(--color-third);
  padding: 0;
  margin: 0;
  cursor: pointer;
  align-items: center;
}

@media screen and (max-width: 959px) {
  .footer__rules.am {
    width: 250px;
  }
}

@media screen and (max-width: 669px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px;
    padding-left: 20px;
    box-sizing: border-box;
    height: initial;
  }

  .footer__text {
    margin-top: 20px;
  }
}
