.ellipse-about {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.ellipse-8 {
  background: url("../../../images/Ellipse8.png");
  width: 730px;
  height: 459px;
}

.ellipse-9 {
  background: url("../../../images/Ellipse9.png");
  width: 388px;
  height: 459px;
}

@media screen and (max-width: 1919px) {
  .ellipse-8 {
    width: 500px;
  }

  .ellipse-9 {
    width: 188px;
  }
}

@media screen and (max-width: 1439px) {
  .ellipse-8 {
    width: 540px;
  }

  .ellipse-9 {
    width: 185px;
  }
}

@media screen and (max-width: 959px) {
  .ellipse-8 {
    background: url("../../../images/Ellipse8_s.png");
    width: 384px;
    height: 509px;
  }

  .ellipse-9 {
    background: url("../../../images/Ellipse9_s.png");
    width: 143px;
    height: 509px;
  }
}

@media screen and (max-width: 669px) {
  .ellipse-about {
    display: none;
  }
}
