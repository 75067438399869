.menu__container {
  position: relative;
  background: var(--color-third);
  padding: 0 52px;
  width: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.menu__close-btn {
  position: absolute;
  width: 48px;
  height: 48px;
  background: transparent center url(../../images/close.svg);
  background-size: auto;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  transition: 0.7s;
  top: 20px;
  right: 32px;
}

.popup_type_menu {
  justify-content: flex-end;
}

.menu__close-btn:hover {
  opacity: 0.5;
}

@media screen and (max-width: 669px) {
  .menu__container {
    width: 100%;
    padding: 0 20px;
  }

  .menu__close-btn {
    right: 20px;
  }
}
