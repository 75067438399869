.intro {
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin: 0;
  margin-top: 145px;
  width: 733px;
  position: relative;
}

.intro__title {
  margin: 0;
  font-weight: 900;
  font-size: 60px;
  line-height: 73px;
  color: var(--color-main);
}

.intro__text {
  margin: 0;
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-second);
}

.intro__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 48px;
}

.intro__arrow {
  width: 48px;
  height: 48px;
  position: absolute;
  top: -15px;
  right: -30px;
}

@media screen and (max-width: 1439px) {
  .intro {
    margin-top: 90px;
    margin-left: 40px;
    width: 513px;
  }

  .intro__title {
    font-size: 48px;
    line-height: 58px;
    margin-top: 16px;
  }

  .intro__arrow {
    width: 42px;
    height: 42px;
  }

  .intro.am {
    width: 541px;
  }
}

@media screen and (max-width: 959px) {
  .intro {
    width: calc(513 / 617 * 100%);
    min-width: 513px;
    margin-left: 0;
    margin-top: 90px;
  }

  .intro__arrow {
    right: -25px;
    top: -15px;
  }

  .intro.am {
    width: 589px;
  }
}

@media screen and (max-width: 669px) {
  .intro {
    width: calc(320 / 360 * 100%);
    min-width: 320px;
    margin: 0 auto;
    margin-top: 55px;
  }

  .intro__title {
    font-size: 32px;
    line-height: 39px;
  }

  .intro__text {
    font-size: 14px;
    line-height: 20px;
  }

  .intro__actions {
    margin-top: 32px;
  }

  .intro__arrow {
    display: none;
  }

  .intro.am {
    width: calc(320 / 360 * 100%);
    min-width: 320px;
  }
}
