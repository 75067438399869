.product{
    padding-top: 126px;
    padding-bottom: 140px;
    margin-left: 254px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 2;
}

.product__info{
    width: 544px;
    margin: 0;
}

.product__title{
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    color: var(--color-main);
    margin: 0;
}

.product__subtitle{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-second);
    margin: 0;
    margin-top: 16px;
}

.product__text{
    margin: 0;
    margin-top: 60px;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-main);
    padding-bottom: 31px;
}

.product__items{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
}

.product__item{
    margin: 0;
    padding: 0;
    margin-left: 64px;
}

.product__item:first-of-type{
    margin-left: 0;
}

.product__image{
    margin-left: 27px;
    width: 301px;
    height: 406px;
}

.product__info.am {
    width: 637px;
}

@media screen and (max-width: 1919px) {
    .product{
        margin-left: calc(254/1440*100%);
    }
}


@media screen and (max-width: 1439px) {
    .product{
      width: 621px;
      margin-left: calc(207/960*100%);
      padding-bottom: 175px;
    }

    .product__image{
        position: absolute;
        width: 177px;
        height: 239px;
        top: 90px;
        right: -50px;
    }

    .product__info.am {
        width: 544px;
    }
  }

@media screen and (max-width: 959px) {
    .product{
        width: 606px;
        margin: 0 auto;
        margin-left: 0;
        padding-bottom: 100px;
    }

    .product__box{
        width: 606px;
    }

    .product__item{
        margin-left: 56px;
    }

    .product__image{
        width: 120px;
        height: 170px;
        top: 90px;
        right: 40px;
    }
}

@media screen and (max-width:669px) {
    .product{
        padding-top: 80px;
        width: 100%;
        margin: 0;
        padding-bottom: 80px;
    }

    .product__box{
        width: 320px;
        margin: 0 auto;
    }

    .product__image{
        display: none;
    }

    .product__info{
        width: 320px;
    }

    .product__title{
        font-size: 32px;
        line-height: 39px;
    }

    .product__text{
        margin-top: 52px;
        padding-bottom: 29px;
    }
    
    .product__items{
        flex-direction: column;
    }

    .product__item{
        margin-left: 0;
        margin-top: 26px;
    }

    .product__info.am {
        width: 320px;
    }
}