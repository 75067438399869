.popup_type_lang {
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
  top: 65px;
  right: 555px;
  opacity: 0;
  transition: visibility 0.4s, opacity 0.4s ease-in-out;
  z-index: 6;
}

.popup_type_lang_is-opened {
  opacity: 1;
  visibility: visible;
}

.lang__box {
  width: 150px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: var(--color-third);
}

.lang__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}

.lang__btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-main);
}

.lang__btn:disabled {
  opacity: 0.6;
}

.lang__item {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: var(--color-main);
}

.lang__item:nth-of-type(2) {
  margin-top: 20px;
}

.lang__choosen {
  position: absolute;
  top: 4px;
  right: 0;
  width: 14px;
  height: 10px;
}

.inactive {
  opacity: 0.6;
}

@media screen and (max-width: 1919px) {
  .popup_type_lang {
    right: 325px;
  }
}

@media screen and (max-width: 1439px) {
  .popup_type_lang {
    right: 285px;
  }
}

@media screen and (max-width: 959px) {
  .popup_type_lang {
    top: 75px;
  }
}

@media screen and (max-width: 669px) {
  .popup_type_lang {
    left: 20px;
    top: 70px;
  }
}
