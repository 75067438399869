.about {
  background: var(--color-gradient);
  padding-left: 397px;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
}

.about__box {
  width: 754px;
  padding-bottom: 80px;
  z-index: 2;
}

.about__box.am{
  width: 849px;
}

.about__title {
  margin: 0;
  padding-top: 80px;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;
  max-width: 620px;
  color: var(--color-third);
}

.about__text {
  margin: 0;
  margin-top: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-third);
}

.btn.about__btn {
  margin-top: 24px;
  padding: 0;
  align-items: center;
}

@media screen and (max-width: 1919px) {
  .about {
    padding-left: calc(156 / 1440 * 100%);
  }

  .about.am {
    padding-left: calc(156 / 1440 * 100%);
  }
}

@media screen and (max-width: 1439px) {
  .about {
    padding: 0;
  }

  .about__box {
    width: 703px;
    margin: 0 auto;
  }

  .about.am {
    padding-left: calc(80 / 1440 * 100%);
  }

  .about__title.am {
    padding-top: 68px;
  }

  .about__box.am {
    margin: 0;
    width: 800px;
    padding-bottom: 68px;
  }
}

@media screen and (max-width: 959px) {
  .about__box {
    width: 606px;
  }

  .about.am {
    padding:0
  }

  .about__title.am {
    padding-top: 68px;
  }

  .about__box.am {
    margin: 0 auto;
    width: 606px;
  }
}

@media screen and (max-width: 669px) {
  .about {
    padding-right: 20px;
    padding-left: 32px;
  }

  .about__box {
    width: calc(308 / 360 * 100%);
    min-width: 308px;
    padding-bottom: 64px;
  }

  .about__title {
    padding-top: 60px;
    font-size: 32px;
    line-height: 39px;
  }

  .about__title.am {
    padding-top: 60px;
  }

  .about__box.am {
    width: calc(308 / 360 * 100%);
    min-width: 308px;
    padding-bottom: 60px;
  }
}
