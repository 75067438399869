.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
  margin: 0 320px;
  z-index: 2;
}

.header__logo {
  width: 140px;
  height: 60px;
}

.header__menu {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.header__icon {
  width: 32px;
  height: 32px;
}

.header__lang {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  margin-left: 16px;
  color: var(--color-third);
}

.header__menu-btn {
  display: none;
}

.btn.header__btn {
  margin-left: 65px;
  padding: 0;
  align-items: center;
}

.btn.header__btn:nth-of-type(2) {
  margin-left: 65px;
}

.header__lang-btn {
  background: url(../../images/selector.svg) transparent center;
  background-size: auto;
  cursor: pointer;
  border: none;
  transition: 0.7s;
  width: 14px;
  height: 10px;
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

@media screen and (max-width: 1919px) {
  .header {
    margin: 0 80px;
  }
}

@media screen and (max-width: 1439px) {
  .header {
    margin: 0 40px;
  }

  .header__logo {
    width: 120px;
    height: 51px;
  }

  .btn.header__btn{
    margin-left: 36px;
  }
}

@media screen and (max-width: 959px) {
  .header__menu-btn {
    display: flex;
    border: none;
    background: transparent;
    width: 48px;
    height: 48px;
    background-image: url(../../images/menu.svg);
  }

  .header {
    margin: 0 32px;
  }

  .header__menu {
    display: none;
  }

  .header__menu.mobile {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
  }

  .header__lang.mobile {
    color: var(--color-main);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }

  .header__lang-btn.mobile {
    background: url(../../images/selector_black.svg) transparent center no-repeat;
  }
}

@media screen and (max-width: 669px) {
  .header {
    margin: 0 20px;
  }
}
