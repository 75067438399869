.ellipse-contacts {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.ellipse-5 {
  background: url("../../../images/Ellipse5.png");
  background-repeat: no-repeat;
  width: 1035px;
  height: 860px;
}

.ellipse-6 {
  background: url("../../../images/Ellipse6.png");
  width: 930px;
  height: 860px;
}

.ellipse-7 {
  background: url("../../../images/Ellipse7.png");
  width: 328px;
  height: 589px;
  top: 0;
}

@media screen and (max-width: 1919px) {
  .ellipse-7 {
    width: 128px;
  }
}

@media screen and (max-width: 1690px) {
  .ellipse-5 {
    width: 850px;
  }

  .ellipse-6 {
    width: 670px;
  }
}

@media screen and (max-width: 1445px) {
  .ellipse-5 {
    width: 800px;
  }

  .ellipse-6 {
    width: 700px;
  }
}

@media screen and (max-width: 1439px) {
  .ellipse-5 {
    width: 890px;
  }

  .ellipse-6 {
    width: 630px;
  }

  .ellipse-7 {
    display: none;
  }
}

@media screen and (max-width: 1220px) {
  .ellipse-5 {
    width: 650px;
  }

  .ellipse-6 {
    width: 500px;
  }
}

@media screen and (max-width: 959px) {
  .ellipse-5 {
    width: 1035px;
  }

  .ellipse-6 {
    width: 565px;
  }
}

@media screen and (max-width: 690px) {
  .ellipse-5 {
    width: 800px;
  }
}

@media screen and (max-width: 669px) {
  .ellipse-5 {
    width: 860px;
  }
  .ellipse-6 {
    background: url("../../../images/Ellipse6_s.png");
    width: 360px;
    height: 596px;
  }
}
