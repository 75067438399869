.privacy__container {
  width: 1152px;
  padding: 0;
}

.popup_type_privacy {
  overflow: auto;
  padding-top: 120px;
  padding-bottom: 100px;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
}

.privacy__title {
  margin: 0 auto;
}

.privacy__text {
  white-space: pre-line;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-main);
  margin: 0;
}

.privacy__box {
  background-color: var(--color-third);
  width: 100%;
  margin-top: 60px;
  height: 100%;
  border-radius: 16px;
}

.privacy__textbox {
  width: 906px;
  margin: 0 auto;
  padding-bottom: 95px;
}

@media screen and (max-width: 1919px) {
  .privacy__textbox {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 1439px) {
  .privacy__container {
    width: 890px;
  }

  .privacy__textbox {
    width: 730px;
  }

  .popup_type_privacy {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 959px) {
  .privacy__container {
    width: 606px;
  }

  .privacy__textbox {
    width: 486px;
  }
}

@media screen and (max-width: 669px) {
  .privacy__container {
    width: 312px;
  }

  .privacy__textbox {
    width: 248px;
  }
}
