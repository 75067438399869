.mission {
  display: flex;
  margin-top: 157px;
  z-index: 2;
}

.mission__box {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 24px 32px;
  width: 365px;
  background: var(--color-third);
  border-radius: 24px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white), var(--color-gradient);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  margin-left: 24px;
}

.mission__box:nth-of-type(1) {
  margin-left: 0;
}

.mission__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mission__image {
  width: 51px;
  height: 44px;
}

.mission__title {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-main);
  margin-left: 21px;
}

.mission__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-second);
  margin-top: 15px;
  width: 300px;
}

@media screen and (max-width: 1439px) {
  .mission {
    margin-top: 80px;
    width: 880px;
  }

  .mission__box {
    width: 278px;
  }

  .mission__text {
    width: initial;
  }
}

@media screen and (max-width: 959px) {
  .mission {
    flex-direction: column;
    margin-top: 120px;
    width: initial;
  }

  .mission__box {
    width: 605px;
    margin: 0;
    margin-top: 24px;
  }

  .mission__box:nth-of-type(1) {
    margin: 0;
  }
}

@media screen and (max-width: 669px) {
  .mission {
    margin-top: 80px;
  }
  .mission__box {
    width: 320px;
    margin: 0 auto;
    margin-top: 24px;
  }
  .mission__box:nth-of-type(1) {
    margin: 0 auto;
  }
}