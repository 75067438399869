.popup {
  display: flex;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.4s, opacity 0.4s ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.popup_is-opened {
  visibility: visible;
  opacity: 1;
}

.popup__close-btn {
  position: absolute;
  width: 25px;
  height: 25px;
  background: transparent center url(../../images//exit.svg);
  background-size: auto;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  transition: 0.7s;
  top: 40px;
  right: 40px;
}

.popup__close-btn:hover {
  opacity: 0.5;
}

.popup__save-btn.btn:nth-of-type(2) {
  margin: 0;
  margin-top: 48px;
}

.popup__container {
  position: relative;
  box-sizing: border-box;
  width: 619px;
  color: #000000;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background: var(--color-third);
  padding: 60px 80px;
  border-radius: 16px;
}

.popup__section {
  position: relative;
  margin: 0;
  padding: 0;
  width: 459px;
  margin-top: 24px;
}

.popup__input {
  padding: 0;
  margin: 0;
  width: 405px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 405px;
  height: 59px;
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  color: black;
}

.popup__title {
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  color: var(--color-main);
  margin: 0;
}

.popup__input-error {
  position: absolute;
  opacity: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  top: 64px;
  left: 0px;
  z-index: 2;
  color: var(--color-four);
}

.popup__input-error.contact {
  color: var(--color-third);
}

.popup__input-error_active {
  opacity: 1;
}

@media screen and (max-width: 669px) {
  .popup__container {
    width: 312px;
    padding: 60px 32px;
  }

  .popup__title {
    font-size: 32px;
    line-height: 39px;
  }

  .popup__section {
    width: 248px;
  }

  .popup__input {
    width: 248px;
  }

  .popup__close-btn {
    top: 24px;
    right: 24px;
  }

  .popup__save-btn.btn {
    width: 100%;
  }
}
